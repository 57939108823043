<template>
	<div class="auth-page">
		<div class="content">
			<div class="header">
				<div class="logo">
				<img src="../assets/images/logo.png" alt="logo" />
				</div>
			</div>
			<div class="data-container">
				<div class="left-content">
					<div class="login-text">
						<h3>
							<span>A private virtual Room</span> <span>to play with </span>
							<span> family &#38; friends</span>
						</h3>
						<p>
							<span>See</span> | <span>Talk</span> | <span>Play</span> |
							<span>Feel</span>
						</p>
					</div>
					<!--b-btn class="gold-button" to="/register">Register</b-btn-->
				</div>
				<div class="right-content">
					<div class="auth-card">
						<p class="heading">Reset Password</p>
						<form>
							<template v-if="!havetoken">
								<input type="email"
									class="input-box"
									v-model.trim="email"
									@keyup.enter="dopasswordreset"
									placeholder="email"
									name="email" />
								<div class="buttons">
									<processingbutton buttonclass="game-button" label="Reset" :onclick="dopasswordreset" :isprocessing="isprocessing" />
								</div>
							</template>
							<template v-else>
								<input type="email"
									class="input-box"
									:value="email"
									name="email"
									readonly />
								<input type="hidden"
									class="input-box"
									v-model.trim="token"
									placeholder="token"
									name="token" />
								<input type="password"
									class="input-box"
									v-model="password"
									placeholder="new password"
									name="password" />
								<input type="password"
									class="input-box"
									v-model="confirmpassword"
									@keyup.enter="dopasswordreset"
									placeholder="confirm new password"
									name="confirmpassword" />
								<div class="buttons">
									<processingbutton buttonclass="game-button" label="Change Password" :onclick="dopasswordreset" :isprocessing="isprocessing" />
								</div>
							</template>
						</form>
						<p class="register">
							Existing user
							<router-link class="link-button" to="/login">Login here</router-link>
						</p>
					</div>
				</div>
			</div>
			<div class="auth-footer">
				<div class="footer-link text-muted font-weight-light">
					<a target="_blank" href="/privacy">Privacy</a> |
					<a target="_blank" href="/terms">Terms of use</a>  |
					<a target="_blanK" HREF="/howto">How to</a>
				</div>
				<p>© 2021 ZoomGameZ. All rights reserved.</p>
			</div>
		</div>
	</div>
</template>
<script lang="js">
	import { mapActions, mapState } from 'vuex'
	import DEBUG from '@/common/DEBUG'
	import processingbutton from '@/components/processingbutton'

	export default {
		name: 'passwordreset',

		components: {
			processingbutton
		},

		data() {
			return {
				email: '',
				havetoken: false,
				token: '',
				password: '',
				confirmpassword: '',
			}
		},

		computed: {
			...mapState(
				['isprocessing', 'haserror', 'error']
			),
		},

		mounted() {
			const that = this

			const query = that.$route.query
			const token = query.token;
			const email = query.email;

			if (token) {
				that.email = email;
				that.token = token;
				that.havetoken = true;
			}
		},

		methods: {
			...mapActions(
				['passwordReset', 'passwordResetChallenge']
			),

			async dopasswordreset() {
				const that = this;
				const havetoken = that.havetoken;

				if (!havetoken) {
					const email = that.email
					if (email == '') {
						alert('email is required. please fill and try again')
						return
					}

					try {
						await that.passwordReset({ email });
						await that.notifySuccess(`password reset initiated, please check the registered email for instructions`)
					}
					catch (error) {
						DEBUG.log('ERROR when resetting the password - %o', error)
						that.notifyError(`Unable to reset the password - ${error.message}`)
					}
				}
				else {
					const email = that.email
					if (email == '') {
						alert('email is required. please fill and try again')
						return
					}
					const token = that.token
					if (token == '') {
						alert('token is required. please fill and try again')
						return
					}
					const password = that.password
					if (password == '') {
						alert('password is required. please fill and try again')
						return
					}
					const confirmpassword = that.confirmpassword
					if (confirmpassword == '') {
						alert('confirmpassword is required. please fill and try again')
						return
					}
					if (confirmpassword != password) {
						alert('password/confirmpassword does not match. please try again')
						return
					}

					try {
						var change = false;
						var userDisplayName = '';
						await that.passwordResetChallenge({ email, change, userDisplayName,token, password });
						await that.notifySuccess(`password has been reset`)
						await that.$router.replace({ name: 'login' })
					}
					catch (error) {
						DEBUG.log('ERROR when resetting the password - %o', error)
						that.notifyError(`Unable to reset the password - ${error.message}`)
					}
				}
			},
		}
	}
</script>